<template>
  <div>
    <WaBtn />
    <!-- main header -->
    <MainBanner />
    <!-- end main header -->

    <!-- japanese brands -->
    <div class="py-10 bg-black">
      <div class="container">
        <h2 class="text-4xl font-semibold text-center text-pw-gold">
          Japanese Piano Brands
        </h2>
        <div class="py-4 lg:max-w-3xl lg:mx-auto">
          <p class="text-sm text-center text-white lg:text-md">
            The bulk of our inventory consists mainly of used imported pianos
            directly from Japan. These pianos have gone through stringent
            refurbishing and quality controls before they are being on sale.
            They are excellent for their quality, stability, and value for
            money, retailing at a fraction of the price of a new one.
          </p>
        </div>

        <!-- brands slider -->
        <div class="pt-3 lg:py-6">
          <carousel
            :loop="true"
            :autoplay="true"
            :dots="false"
            :autoplaySpeed="3000"
            :autoplayTimeout="3000"
            :responsive="{
              0: { items: 2, nav: false },
              700: { items: 3, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in brands" :key="i" class="px-1">
              <img :src="item.image" :alt="item.alt" class="rounded-sm" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- benefits buying piano -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h2 class="text-4xl font-semibold text-center text-pw-gold">
          Benefits of Buying a Used Piano
        </h2>
        <div class="pt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in benefits"
            :key="i"
            class="py-1 md:px-1 md:w-1/2 lg:w-1/4"
          >
            <div class="py-6 bg-white rounded-lg hCustom boxShadow">
              <img :src="item.image" :alt="item.alt" class="" />
              <h1 class="text-lg font-semibold text-center text-lite-gold">
                {{ item.alt }}
              </h1>
              <p class="px-2 py-5 text-sm text-center text-gray-700">
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Services -->
    <div class="py-10">
      <div class="container">
        <h2 class="text-4xl font-semibold text-center text-pw-gold">
          Our Services
        </h2>
        <div class="pt-10">
          <div v-for="(item, i) in service" :key="i" class="pb-6 md:py-10">
            <div class="md:flex md:items-center">
              <div class="md:w-1/2" :class="item.order">
                <img
                  :src="item.image"
                  :alt="item.alt"
                  class="md:mx-auto lg:w-3/4"
                />
              </div>
              <div class="pt-5 md:w-1/2 md:px-5">
                <h1 class="text-xl font-semibold text-left text-pw-gold">
                  {{ item.alt }}
                </h1>
                <div class="w-10 h-1 mt-3 bg-pw-gold"></div>
                <p class="py-4 text-sm text-gray-700 lg:text-md md:text-left">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-black bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/why-choose.jpg')"
    >
      <div class="container">
        <div class="py-10 lg:py-20">
          <!-- <img src="/images/why-choose.jpg" alt="" class="w-full" /> -->
          <h2
            class="text-4xl font-semibold text-center lg:text-5xl text-pw-gold"
          >
            Why Choose Piano Warehouse?
          </h2>
          <div class="pt-4 lg:pt-6">
            <p
              class="text-sm font-medium text-center text-white md:text-base lg:text-lg"
            >
              We deliver each piano as an ambassador of our commitment to
              discerning customers:
            </p>
            <div class="md:max-w-2xl md:mx-auto">
              <ul
                class="px-2 pt-6 text-sm text-left text-white list-disc lg:text-base inside"
              >
                <li class="py-2 pl-2">
                  The integrity of pianos in their genuine condition, restored
                  to authentic elegance
                </li>
                <li class="py-2 pl-2">
                  Uncompromising standards for meticulous quality, reliable
                  performance and trustworthy service
                </li>
                <li class="py-2 pl-2">
                  That our in-house expertise and experience translates to
                  unparalleled savings
                </li>
                <li class="py-2 pl-2">
                  Knowing That These Values Have Differentiated Our Success For
                  Over 20 Years.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <h2 class="text-4xl font-semibold text-center text-pw-gold">
          About Piano Warehouse
        </h2>
        <div
          class="pt-6 text-sm text-center text-gray-700 lg:text-md lg:max-w-3xl lg:mx-auto"
        >
          <p class="py-4">
            <b>Piano Warehouse</b> had been set up specifically to satisfy our
            client’s needs in the purchase of used pianos. Many confusions
            abound on the reliability, consistency and the performance of these
            used pianos.
          </p>
          <p class="py-4">
            At <b>Piano Warehouse</b>, you can rest easy knowing that
            <b
              >every of our pianos had been meticulously calibrated and properly
              regulated to give optimal performances. Our external bodywork had
              been painstakingly refurbished to give the brightest shine and
              restoring the “new look” when delivered to our client’s home.</b
            >
            List of clientele includes Royalties, professional performers, music
            lecturers, teachers, students and hobbyist. Every piano bought from
            Piano Warehouse are assured to last and we back this assurance with
            our comprehensive warranties.
          </p>
          <p class="py-4">
            We are the
            <b
              >direct importer from the biggest supplier of used pianos from
              Japan for 20 years through our various subsidiaries.</b
            >
            This facilitates the access to the widest varieties of piano models.
            We had imported special models that are rarely found in the market
            and are open to special orders from our clients. All original spare
            parts are sourced from our suppliers direct from Japan. Thus, we
            maintain strict consistency in our quality. We uphold our belief in
            conducting business with direct honesty and integrity.
          </p>
          <p class="py-4">
            There are a number of cases customers inquiring about their children
            interest before piano purchase. Through our experience, we are able
            to highlight the advantages and pitfalls of musical learning
            benefiting our clients.
            <b
              >We gladly share our knowledge to our client so they are able to
              choose a proper piano for their children.</b
            >
          </p>
        </div>
      </div>
    </div>

    <!-- enquiry form -->
    <div class="py-8 bg-pw-gold">
      <div class="container">
        <div id="element" class="lg:max-w-3xl lg:mx-auto">
          <div class="pb-4">
            <h1 class="text-3xl font-bold text-center text-white md:text-2xl">
              Send an Enquiry
            </h1>
            <p class="pt-3 text-sm text-white">
              Fill out the form below and we'll get back to you as soon as
              possible
            </p>
          </div>
          <!-- feedback.activamedia.com.sg script begins here -->
          <div id="enquire">
            <iframe
              allowTransparency="true"
              style="min-height: 550px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="//feedback.activamedia.com.sg/my-contact-form-5661341.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            >
          </div>
          <!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>
    <!-- end enquiry form -->
    <!-- footer -->
    <div class="py-8">
      <div class="container">
        <div>
          <img
            src="/images/main-logo-black.png"
            alt="myGD Express"
            class="w-1/2 mx-auto md:w-1/3 lg:w-52"
          />
          <div class="py-4">
            <p class="text-base font-medium text-center text-gray-900">
              No. 2, Jalan TS6, Off Jalan Subang 5, 47620 Subang Jaya, Selangor.
            </p>
            <p class="pt-5 text-base font-medium text-center">
              Call Us At :
              <a
                id="call-btn-b1"
                href="tel:+60196631998"
                class="text-gray-900 hover:text-pw-gold"
                >019-663 1998</a
              >

              <!-- <a
                id="call-btn-b2"
                href="tel:+60356374430"
                class="text-gray-900 hover:text-pw-gold"
                >03-5637 4430</a
              > -->
            </p>
            <div class="pt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.2060687549183!2d101.59500964994719!3d3.0393488977757617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb35d819efe13%3A0xbacf5997d5573585!2sPiano%20Warehouse%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1620281249423!5m2!1sen!2smy"
                width="100%"
                height="200"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                class="mx-auto"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end footer -->
    <Copyright />
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  components: {
    carousel,
    WaBtn,
    MainBanner,
    Copyright,
  },
  data() {
    return {
      brands: [
        { image: "/images/used/used-piano-01.jpg", alt: "used piano 01" },
        { image: "/images/used/used-piano-02.jpg", alt: "used piano 02" },
        { image: "/images/used/used-piano-03.jpg", alt: "used piano 03" },
        { image: "/images/used/used-piano-04.jpg", alt: "used piano 04" },
        { image: "/images/used/used-piano-05.jpg", alt: "used piano 05" },
        { image: "/images/used/used-piano-06.jpg", alt: "used piano 06" },
        { image: "/images/used/used-piano-07.jpg", alt: "used piano 07" },
        { image: "/images/used/used-piano-08.jpg", alt: "used piano 08" },
      ],
      benefits: [
        {
          image: "/images/benefit-1.png",
          alt: "Affordable Price",
          p: "50% to 70% cheaper compared to buying a brand-new piano.",
        },
        {
          image: "/images/benefit-2.png",
          alt: "Perfect Choice for Beginners ",
          p:
            "offers better opportunity to first learn to play the piano before deciding on a more expensive upgrade",
        },
        {
          image: "/images/benefit-3.png",
          alt: "Quality Materials",
          p:
            "Japan spec models are made of quality built-up materials, compared to some new entry level uprights and grand pianos.",
        },
        {
          image: "/images/benefit-4.png",
          alt: "Durable",
          p:
            "with frequent care and maintenance, well-regulated action and proper restoration of the piano parts, a piano could easily last for over 20-50 years.",
        },
      ],
      service: [
        {
          image: "/images/service-1.jpg",
          alt: "Piano Repairs & Restoration",
          p:
            "Extensive Re-Manufacturing: The process of properly restoring a piano is much more extensive than a simple cosmetic refinish or refurbishment of mechanical parts. The restoration work we complete is also known as ‘rebuilding’ or ‘re-manufacturing’ due to how complete this work is. Restoring a piano to the extent that we do here at Piano Warehouse requires a broad base of knowledge and vast experience to ensure like new condition and true concert-level performance. Our team of piano rebuilders go through the piano and every component from the soundboard to the action is carefully evaluated and then repaired, restored or fully replaced",
        },
        {
          image: "/images/service-2.jpg",
          order: "order-2",
          alt: "Piano Refinishing",
          p:
            "Cosmetic Restoration: The refinishing of a piano is purely cosmetic. This is done to refresh the instrument and return a like new look and feel to the exterior of the piano cabinet. When we refinish a piano we utilize the finest materials and workmanship to ensure a premium, like new finish. We utilize closed pore, hand rubbed lacquer finishes over the quicker sprayed-only method. This ensures a finish with optimum clarity and one that is more easily repairable in the future.",
        },
        {
          image: "/images/service-3.jpg",
          alt: "Piano Refurbishing",
          p:
            "Basic Repairs & Regulation: Refurbishing the piano can vary in the extent of the work completed, however when we refurbish a piano it usually involves carefully cleaning the interior and exterior of the piano, ensuring tight tuning pins, completing our extensive action and tone regulation, repairing any small action defects and tuning the instrument for delivery. Often new strings are an option as well. If the cabinet is in overall good condition, we do some cleaning and minor finish touch-up. This is usually done to pianos that are already in good to excellent condition and do not require a full restoration",
        },
        {
          image: "/images/service-4.jpg",
          alt: "Piano Tuning",
          order: "order-2",
          p:
            "Piano tuning is carried on all pianos to make sure the strings vibrate to the right pitch. The strings are adjusted until the correct pitch is met and this involves very skilled work as well as the correct tools.",
        },
      ],
    };
  },
};
</script>
<style>
.owl-carousel .owl-stage {
  transition-timing-function: linear !important;
}
.boxShadow {
  box-shadow: 3px 3px 4px #dbdbdb;
}
@media (min-width: 768px) {
  .hCustom {
    height: 540px;
  }
  #enquire iframe {
    min-height: 490px !important;
  }
}
@media (min-width: 1024px) {
  .hCustom {
    height: 460px;
  }
}
@media (min-width: 1280px) {
  .hCustom {
    height: 530px;
  }
}
</style>
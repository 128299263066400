<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-28 lg:py-20">
        <img
          src="/images/main-logo.png"
          alt="Piano Warehouse"
          class="w-1/2 mx-auto lg:w-1/6 md:w-1/3"
        />
        <div class="pt-4">
          <h1
            class="text-4xl font-bold uppercase lg:pb-5 md:text-6xl text-pw-gold"
          >
            Used Pianos
          </h1>
          <h2 class="text-2xl text-white md:text-3xl lg:text-4xl">
            Beautifully Refurbished At An Affordable Price
          </h2>
        </div>

        <!-- cta button -->
        <div class="pt-6 md:flex md:justify-center">
          <a
            v-if="!hideEnquiry"
            href="#"
            v-scroll-to="{ el: '#element', duration: 1500 }"
            class="block py-2 mx-auto mb-1 text-base font-semibold text-center border hover:text-white hover:bg-pw-gold text-pw-gold md:mr-2 md:mb-0 w-52 border-pw-gold md:mx-0"
            >ENQUIRE US NOW!</a
          >
          <a
            id="call-btn-banner"
            href="tel:+60196631998"
            class="block py-2 mx-auto text-base font-semibold text-center border hover:text-white hover:bg-pw-gold text-pw-gold w-52 border-pw-gold md:mx-0"
            >CALL US : 019-663 1998</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>